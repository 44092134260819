/**
 * The default structure of the response returned from an RPC.
 */
export interface DefaultRPCResponse {
	status: number;
	success: boolean;
	message?: string;
	data?: unknown;
}

/**
 * A factory function that creates a standardised RPC response object.
 */
function response (status: number, success: boolean, message?: string, data?: unknown): DefaultRPCResponse {
	const response: DefaultRPCResponse = { status, success };
	if (typeof message === 'string') response.message = message;
	if (data) response.data = data;
	return response;
}

/**
 * A standardised RPC response.
 */
export const RPCResponse = {
	Custom:                      response,
	/** `200`: The request was successful. */
	Success:                     (message?: string, data?: unknown) => response(200, true, message, data),
	/** `303`: The response to the request can be found elsewhere. */
	SeeOther:                    (message?: string, data?: unknown) => response(303, true, message, data),
	/** `400`: The request is invalid. */
	BadRequest:                  (message?: string, data?: unknown) => response(400, false, message, data),
	/** `402`: A subscription is required to access this resource. */
	SubscriptionRequired:        (message?: string, data?: unknown) => response(402, false, message, data),
	/** `403`: An account is required to access this resource. */
	AccountRequired:             (message?: string, data?: unknown) => response(403, false, message, data),
	/** `404`: The requested resource cannot be found. */
	NotFound:                    (message?: string, data?: unknown) => response(404, false, message, data),
	/** `409`: This request conflicts with the current state of the resource. */
	ResourceStateConflict:       (message?: string, data?: unknown) => response(409, false, message, data),
	/** `413`: The request payload is too large. */
	TooLong:                     (message?: string, data?: unknown) => response(413, false, message, data),
	/** `449`: A verified account is required to access this resource. */
	AccountVerificationRequired: (message?: string, data?: unknown) => response(449, false, message, data),
	/** `500`: The request failed due to a server error. */
	ServerError:                 (message?: string, data?: unknown) => response(500, false, message, data),
	/** `503`: The requested resource is unavailable. */
	Unavailable:                 (message?: string, data?: unknown) => response(503, false, message, data)
} as const;
