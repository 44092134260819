<script setup lang="ts">
import { computed } from 'vue';
import Icon from '@/components/Icon.vue';

const props = defineProps({
	title: { type: String, required: true },
	date: { type: Number, required: true },
	loading: { type: Boolean, required: false },
	selected: { type: Boolean, required: false }
});

const emit = defineEmits(['settings']);
const dateString = computed((): string => new Date(props.date).toDateString());
</script>

<template>
	<button class="projectLink" :class="{ loading, selected }" data-journey-id="ProjectLink">
		<p>{{ title }}</p>
		<p>{{ dateString }}</p>
		<button title="Settings" @click.stop="emit('settings')" data-journey-id="ProjectLinkSettings">
			<Icon>settings</Icon>
		</button>
	</button>
</template>

<style scoped>
.projectLink {
	display: grid;
	grid-template-columns: 1fr min-content;
	grid-template-rows: auto auto;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0 1ch;
	width: 100%;
	padding: 0.25em 1ch 0.4em;
	font-size: 1rem;
	transition:
		0.1s ease background-color,
		0.1s ease border-color,
		0.1s ease opacity;
	cursor: pointer;
	user-select: none;
}
.projectLink:hover:not(.selected):not(:has(> button:hover)) {
	background-color: transparent;
}
.projectLink:hover {
	border-color: var(--color-primary);
}
.projectLink.selected,
.projectLink.selected:hover {
	background-color: var(--color-primary-harmony);
}
.projectLink.loading {
	opacity: 0.5;
	pointer-events: none;
}

.projectLink > p {
	margin: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.projectLink > p:last-of-type {
	font-size: small;
	opacity: 0.6;
}

.projectLink > button {
	grid-area: 1 / 2 / 3 / 3;
	align-self: center;
	opacity: 0.3;
	transition: 0.1s ease background-color, 0.1s ease opacity;
}
.projectLink:hover > button {
	opacity: 1;
}
</style>
