<script setup lang="ts">
import { ref, type PropType } from 'vue';
import Icon from '@/components/Icon.vue';

const props = defineProps({
	modelValue: { type: Array as PropType<Array<string>>, required: true },
	name: { type: String, required: true },
	placeholder: { type: String, required: false }
});

const emit = defineEmits(['update:modelValue']);
const input = ref('');
const tagInputLabel = ref<HTMLLabelElement | null>(null);

function addTag (): void {
	if (input.value && !props.modelValue.includes(input.value)) {
		const tags = props.modelValue;
		tags.push(input.value);
		emit('update:modelValue', tags);
	}
	input.value = '';
}
function removeTag (tag: string): void {
	const tags = props.modelValue;
	tags.splice(tags.indexOf(tag), 1);
	emit('update:modelValue', tags);
}
function removeLastTag (): void {
	if (input.value) return;
	const tags = props.modelValue;
	tags.pop();
	emit('update:modelValue', tags);
}
function focusLabel (): void {
	if (!tagInputLabel.value) return;
	tagInputLabel.value.focus();
}
</script>

<template>
	<div class="tagInput" @click="focusLabel()">
		<ul v-if="modelValue.length > 0">
			<li v-for="tag of modelValue" :key="tag" @click.stop>
				<span>{{ tag }}</span>
				<Icon
					role="button"
					class="close"
					@click="removeTag(tag)"
				>close</Icon>
			</li>
		</ul>
		<label ref="tagInputLabel" :for="name">
			<span class="visuallyHidden">New tag</span>
			<input
				type="text"
				:id="name"
				:name
				:placeholder
				maxlength="30"
				@keydown.enter="($event.preventDefault(), addTag())"
				@keydown="$event.code === 'Comma' && ($event.preventDefault(), addTag())"
				@keydown.backspace="removeLastTag()"
				v-model="input"
			>
		</label>
	</div>
</template>

<style scoped>
.tagInput {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
	gap: 1ch;
	padding: 0.5ch;
	color: var(--color-text);
	border: 1px solid var(--color-border-light);
	border-radius: var(--border-radius);
	transition: 0.1s ease border-color;
}
.tagInput:focus-within {
	border-color: var(--color-border);
}
html.dark .tagInput {
	background-color: #121212;
}
.tagInput > ul {
	display: flex;
	flex-flow: row wrap;
	gap: 1ch;
	margin: 0;
	padding: 0;
	list-style: none;
}
.tagInput li {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 1ch;
	padding: 0.5ch 1ch;
	font-size: 0.8rem;
	line-height: 1;
	background-color: var(--color-border-light);
	border-radius: 1em;
}
.tagInput li > .close {
	color: grey;
	cursor: pointer;
}
.tagInput li > .close:hover {
	color: inherit;
}
.tagInput > label {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.tagInput > label > input {
	display: inline-block;
	min-width: 10ch;
	color: inherit;
	background: transparent;
	border: unset;
	outline: unset;
}
</style>
