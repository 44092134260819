<script setup lang="ts">
import { ref } from 'vue';
import { flagsStore } from '@/main';
import Firebase from '@/modules/firebase/core';
import { RPC } from '@/modules/firebase/rpc';
import { wall } from '@/modules/firebase/walls';
import { formatDate, formatDateIso } from '@/utilities';
import DialogBox from '@/components/DialogBox.vue';
import Icon from '@/components/Icon.vue';
import PremiumBadge from '@/components/PremiumBadge.vue';

interface RoadmapItem {
	title: string;
	link?: string;
	date: number;
	description: string;
	complete: boolean;
}

const collapsed = ref(true);
const roadmap: Array<RoadmapItem> = [
	{
		title: 'Project Folders',
		date: 0,
		description: '',
		complete: false
	},
	{
		title: 'Optimized CSS Editor',
		date: 0,
		description: '',
		complete: false
	},
	{
		title: 'Variable Editor',
		date: 0,
		description: '',
		complete: false
	},
	{
		title: 'Feeds',
		date: 0,
		description: '',
		complete: false
	},
	{
		title: 'Launch Cascades 🎉',
		date: 0,
		description: '',
		complete: false
	},
	{
		title: 'Support Nested Selectors',
		link: 'https://github.com/Cascades-CSS/CSS-to-HTML/releases/tag/v0.7.0',
		date: 1725912321911,
		description: '',
		complete: true
	},
	{
		title: 'Box Model Display',
		link: 'https://github.com/Cascades-CSS/Box-Model',
		date: 1718446512000,
		description: '',
		complete: true
	},
	{
		title: 'Compiled CSS Preview',
		date: 1718154883000,
		description: '',
		complete: true
	},
	{
		title: 'Support LESS and SCSS',
		date: 1696896000000,
		description: '',
		complete: true
	},
	{
		title: 'Tutorial',
		date: 1695348466000,
		description: '',
		complete: true
	},
	{
		title: 'Support Attribute Selectors',
		date: 1694390400000,
		description: '',
		complete: true
	},
	{
		title: 'Support Imports Between Projects',
		date: 1692576000000,
		description: '',
		complete: true
	},
	{
		title: 'Support "@import" Rules',
		link: 'https://github.com/Cascades-CSS/CSS-to-HTML/releases/tag/v0.5.0',
		date: 1692576000000,
		description: '',
		complete: true
	},
	{
		title: 'Launch Cascades <span class="beta">Beta</span>',
		date: 1692476000000,
		description: '',
		complete: true
	},
	{
		title: 'Support "nth" Selectors',
		link: 'https://github.com/Cascades-CSS/CSS-to-HTML/releases/tag/v0.3.0',
		date: 1688558400000,
		description: '',
		complete: true
	},
	{
		title: 'Community Project Browser',
		link: 'https://cascades.app/browse',
		date: 1686888825000,
		description: '',
		complete: true
	},
	{
		title: 'Shareable Projects',
		date: 1686888825000,
		description: '',
		complete: true
	},
	{
		title: 'CSS Editor',
		link: 'https://demo.cascades.app/',
		date: 1685354582000,
		description: '',
		complete: true
	},
	{
		title: 'Stable HTML Generator',
		link: 'https://github.com/Cascades-CSS/CSS-to-HTML',
		date: 1685309460000,
		description: '',
		complete: true
	}
];
const showSuggestionDialog = ref(false);
const suggestionError = ref(false);
const suggestionLoading = ref(false);
const suggestionMessage = ref('');
const suggestionSent = ref(false);

async function submitSuggestion (): Promise<void> {
	suggestionLoading.value = true;
	const { success } = await RPC(RPC.Endpoint.suggestFeature, { message: suggestionMessage.value });
	suggestionError.value = !success;
	suggestionLoading.value = false;
	suggestionSent.value = success;
}
</script>

<template>
	<article class="roadmapSection" :class="{ collapsed }" data-journey-id="RoadmapSection">
		<div class="inner">
			<ul>
				<li
					v-for="item of roadmap"
					:class="{ complete: item.complete }"
				>
					<Icon
						style="user-select: none;"
						:title="item.complete ? 'Complete' : 'Coming soon'"
					>
						{{ item.complete ? 'done' : 'more_horiz' }}
					</Icon>
					<div>
						<h4 v-if="item.link">
							<a
								:href="item.link"
								target="_blank"
								rel="noreferrer"
								v-html="item.title"
							></a>
							<Icon>open_in_new</Icon>
						</h4>
						<h4 v-else v-html="item.title"></h4>
						<time v-if="item.date" :datetime="formatDateIso(item.date)">{{ formatDate(item.date) }}</time>
						<p v-if="item.description">{{ item.description }}</p>
					</div>
				</li>
			</ul>
		</div>
		<div class="options">
			<button class="lightHover" @click="collapsed = !collapsed">
				<Icon aria-hidden="true">{{ collapsed ? 'expand_more' : 'expand_less' }}</Icon>
				{{ collapsed ? 'More' : 'Less' }}
			</button>
			<button
				v-if="flagsStore.suggestions && Firebase.user.authenticated.value"
				class="lightHover"
				@click="showSuggestionDialog = wall.verified()"
				data-journey-id="RoadmapSuggest"
			>
				<Icon aria-hidden="true">star</Icon>
				Suggest a feature
			</button>
		</div>
	</article>
	<DialogBox
		title="Suggest a Feature"
		v-model="showSuggestionDialog"
		data-journey-id="RoadmapSuggestionMenu"
	>
		<template v-if="suggestionSent">
			<p>Thanks! We'll review your suggestion as soon as possible.</p>
		</template>
		<template v-else>
			<form @submit.prevent="submitSuggestion()">
				<label for="suggestionMessage">Your suggestion
					<textarea
						type="text"
						id="suggestionMessage"
						name="suggestionMessage"
						rows="6"
						maxlength="1000"
						v-model="suggestionMessage"
					></textarea>
				</label>
				<p
					v-if="Firebase.user.subscribed.value"
					class="premiumTip"
				>
					<PremiumBadge />
					<span>Your suggestion will be added to the top of the list.</span>
				</p>
				<p
					v-if="suggestionError"
					style="margin: 0;color: var(--color-danger);font-size: small;"
				><i>Something went wrong. Please try again.</i></p>
				<button type="submit" :disabled="suggestionLoading" data-journey-id="RoadmapSuggestionSend">
					<span v-if="suggestionLoading" class="spinner"></span>
					<template v-else>Send</template>
				</button>
			</form>
		</template>
	</DialogBox>
</template>

<style scoped>
.roadmapSection {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 1fr 2rem;
	place-content: start;
	place-items: start;
	gap: 2rem;
	width: 100%;
	margin-bottom: -14rem;
	padding-top: 1rem;
	transition: 0.5s ease grid-template-rows;
}
.roadmapSection.collapsed {
	grid-template-rows: 0.5fr 2rem;
}

.roadmapSection > .inner {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
	mask-image: unset;
	-webkit-mask-image: unset;
}
.roadmapSection.collapsed > .inner {
	mask-image: linear-gradient(0deg, transparent, #000 5rem);
	-webkit-mask-image: linear-gradient(0deg, transparent, #000 5rem);
}
.roadmapSection ul {
	width: 100%;
	margin: 0;
	padding: 0;
}
.roadmapSection ul::before {
	content: '';
	position: absolute;
	left: 0.75rem;
	display: block;
	width: 1rem;
	height: 100%;
	background:
		radial-gradient(1rem at 50% calc(100% - 0.5rem), transparent calc(35% - 1px), var(--color-border-light) calc(35% - 1px), var(--color-border-light) calc(35% + 1px), transparent calc(35% + 1px)),
		linear-gradient(90deg, transparent calc(50% - 1px), var(--color-border-light) calc(50% - 1px), var(--color-border-light) calc(50% + 1px), transparent calc(50% + 1px));
	background-repeat: no-repeat;
	background-size:
		100% 100%,
		100% calc(100% - 0.9rem);
	-webkit-mask-image: linear-gradient(180deg, transparent, black 1rem);
	mask-image: linear-gradient(180deg, transparent, black 1rem);
}

.roadmapSection li {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: 1rem;
	margin-bottom: 2rem;
}

.roadmapSection li > span.icon {
	padding: 0.35rem;
	color: var(--color-text);
	font-size: 1.7rem;
	line-height: 1;
	background-color: var(--color-background);
	border: 1px solid var(--color-border-light);
	border-radius: 50%;
	opacity: 0.8;
}
.roadmapSection li.complete > span.icon {
	color: var(--color-background);
	background-color: var(--color-primary-contrast);
	border-color: var(--color-primary-contrast);
	opacity: 1;
}

.roadmapSection li > div {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: flex-start;
	gap: 1ch;
	width: 100%;
	padding: 1ch 1.5ch;
	background-color: #ffffff28;
	background: radial-gradient(circle at 0 50%, #ffffff28, transparent);
	border-radius: var(--border-radius);
}

.roadmapSection > .options {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	gap: 1rem;
	width: 100%;
}

.roadmapSection h4 {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	margin: 0;
	font-weight: 400;
	white-space: pre;

	& > .icon {
		margin-inline-start: 0.5ch;
		opacity: 0.7;
	}
}

.roadmapSection time {
	margin: 0;
	font-size: small;
	line-height: 1;
}

.roadmapSection p {
	margin: 0;
}

form {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 1.5rem;
}
form label {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 0.5ch;
	width: 100%;
}

.premiumTip > span {
	font-size: small;
}
</style>

<style>
.roadmapSection h4 .beta {
	padding: 0.2ch 0.6ch 0;
	color: white;
	font-size: 1rem;
	text-decoration: none;
	background-color: var(--color-premium);
	border-radius: var(--border-radius);
}
</style>
