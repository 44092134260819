<script setup lang="ts">
import { nextTick, ref, watch } from 'vue';
import Icon from '@/components/Icon.vue';
import LandingSplash from '@/components/LandingSplash.vue';

const props = defineProps({
	modelValue: { type: Boolean, required: true },
	title: { type: String, required: true },
	message: { type: String, required: false, default: '' },
	background: { type: String, required: false, default: 'var(--color-background)' },
	color: { type: String, required: false, default: 'var(--color-text)' },
	dense: { type: Boolean, required: false },
	fullscreen: { type: Boolean, required: false },
	wide: { type: Boolean, required: false }
});

const emit = defineEmits(['update:modelValue']);

let animation: Animation | undefined;
const closing = ref(false);
const dialog = ref<HTMLDivElement | null>(null);
const popup = ref<HTMLDivElement | null>(null);
const show = ref(false);

function close (): void {
	show.value = false;
	emit('update:modelValue', false);
	nextTick(() => { closing.value = false; });
}

function hide (): void {
	if (closing.value) return;
	closing.value = true;
	if (!dialog.value || !popup.value) {
		close();
		return;
	}
	const keyframes: Keyframe[] = [
		{ opacity: '1' },
		{ opacity: '0' }
	];
	const animationOptions: KeyframeAnimationOptions = {
		duration: 250,
		easing: 'ease',
		fill: 'forwards'
	};
	dialog.value.animate(keyframes, animationOptions);
	animation = popup.value.animate(keyframes, animationOptions);
	animation.onfinish = close;
}

watch(() => props.modelValue, value => {
	if (value) {
		show.value = value;
	} else {
		hide();
	}
}, { immediate: true });
</script>

<template>
	<div
		v-if="show"
		ref="popup"
		class="popup"
		:class="{ fullscreen, wide }"
		:style="{ pointerEvents: closing ? 'none' : 'all' }"
		@click.stop=""
		@pointerup.stop="fullscreen || hide()"
		data-journey-id="DialogOuter"
	>
		<LandingSplash v-if="fullscreen" />
		<div
			v-if="message"
			class="message"
			@click.stop
			@mousedown.stop
			@touchdown.stop
			@pointerdown.stop
			@pointerup.stop
		>{{ message }}</div>
		<div
			ref="dialog"
			class="dialog"
			:class="{ dense }"
			:style="{ backgroundColor: background, color }"
			@click.stop
			@mousedown.stop
			@touchdown.stop
			@pointerdown.stop
			@pointerup.stop
			data-journey-id="DialogBox"
		>
			<button
				class="close"
				@click="hide()"
				data-journey-id="DialogClose"
			><Icon>close</Icon></button>
			<h2 v-if="title">{{ title }}</h2>
			<div class="content">
				<slot></slot>
			</div>
			<div class="bottom">
				<slot name="bottom"></slot>
			</div>
		</div>
	</div>
</template>

<style scoped>
.popup {
	position: fixed;
	z-index: 100;
	inset: 0;
	display: flex;
	flex-flow: column nowrap;
	padding: 2rem 1rem;
	background-color: #000b;
	animation: fadeIn 0.2s ease forwards;
	overflow-y: auto;
}
.popup.fullscreen {
	background-color: var(--color-background);
	animation-duration: 0.5s;
}

.popup h2 {
	margin-top: 0;
	font-size: x-large;
}

.popup > .message {
	display: grid;
	place-items: center;
	width: calc(95% - var(--border-radius) * 2);
	min-width: calc(180px - var(--border-radius) * 2);
	max-width: calc(400px - var(--border-radius) * 2);
	margin: auto;
	margin-bottom: calc(var(--border-radius) * -1);
	padding: 0.7ch 0 calc(var(--border-radius) + 0.7ch);
	font-size: small;
	background-color: var(--color-primary-harmony);
	border-radius: var(--border-radius) var(--border-radius) 0 0;
	box-shadow: 0 0 20px -8px #0004;
}
.popup.wide > .message {
	max-width: calc(50rem - var(--border-radius) * 2);
}

.popup > .dialog {
	width: 95%;
	min-width: 180px;
	max-width: 400px;
	margin: auto;
	padding: 1rem 3ch;
	background-color: var(--color-background);
	border: 1px solid var(--color-border-light);
	border-radius: var(--border-radius);
	box-shadow: 0 0 20px -8px #0004;
	contain: paint;
}
.popup > .dialog.dense {
	padding: 0 0 1rem;
}
.popup.wide > .dialog {
	max-width: 50rem;
}

.popup > .message + .dialog {
	margin-top: 0;
}

.popup > .dialog > .close {
	position: absolute;
	z-index: 2;
	top: 0.5rem;
	right: 0.5rem;
}
.popup > .dialog form {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 1.5rem;
}
.popup > .dialog form label {
	width: 100%;
}

.popup > .dialog .bottom {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	gap: 0 1ch;
	margin-top: 1rem;
	font-size: small;
	opacity: 0.6;
	transition: 0.1s ease opacity;
}
.popup > .dialog .bottom:hover {
	opacity: 1;
}
.popup > .dialog .bottom p {
	display: contents;
}
.popup > .dialog .bottom button {
	display: inline-flex;
	font-size: inherit;
}
</style>
