<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { flagsStore } from '@/main';
import Firebase from '@/modules/firebase/core';
import DialogBox from '@/components/DialogBox.vue';
import DownloadImageMenu from '@/components/DownloadImageMenu.vue';
import Icon from '@/components/Icon.vue';
import PremiumBadge from '@/components/PremiumBadge.vue';

const props = defineProps({
	modelValue: { type: Boolean, required: true },
	project: { type: String, required: true }
});

const emit = defineEmits(['update:modelValue']);
const loading = ref(false);
const projectLink = ref<string | undefined>(undefined);
const shareLink = computed((): string => (projectLink.value ? `${window.location.origin}/project/${projectLink.value}.css` : ''));
const showDownloadImageMenu = ref(false);

async function load (): Promise<void> {
	if (loading.value) return;
	loading.value = true;
	projectLink.value = await Firebase.getProjectLink(props.project);
	loading.value = false;
}

function downloadCss (): void {
	window.open(shareLink.value, '_self');
}

function downloadImage (): void {
	showDownloadImageMenu.value = true;
	emit('update:modelValue', false);
}

async function downloadComponent (): Promise<void> {
	if (!Firebase.user.subscribed.value) return;
}

void load();
watch(() => props.modelValue, (value: boolean) => value && void load());
watch(() => props.project, (value: string) => value && void load());
</script>

<template>
	<DialogBox
		title="Download"
		wide
		:modelValue
		@update:modelValue="emit('update:modelValue', $event)"
		data-journey-id="DownloadMenu"
	>
		<p>Download this project as:</p>
		<div class="downloadButtons">
			<button
				title="Download CSS"
				@click="downloadCss()"
				data-journey-id="DownloadCSS"
			>
				<Icon>code</Icon>
				CSS
			</button>
			<button
				v-if="flagsStore.images"
				title="Download image"
				@click="downloadImage()"
				data-journey-id="DownloadImage"
			>
				<Icon>panorama</Icon>
				Image
			</button>
			<button
				class="premiumDownload"
				title="Download component"
				:disabled="!Firebase.user.subscribed.value"
				@click="downloadComponent()"
				data-journey-id="DownloadComponent"
			>
				<Icon>cards</Icon>
				Component
				<PremiumBadge
					v-if="!Firebase.user.subscribed.value"
					style="position: absolute;top: -0.5em;right: -2ch;transform: rotate(25deg);"
				/>
			</button>
		</div>
	</DialogBox>

	<DownloadImageMenu :project v-model="showDownloadImageMenu" />
</template>

<style scoped>
.downloadButtons {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	margin: 2rem 0;
}

.downloadButtons > button {
	position: relative;
	flex-direction: column;
	min-width: 12ch;
	border: 1px solid var(--color-border-light);

	&.premiumDownload {
		border-color: var(--color-premium-border);

		&:hover {
			background-color: var(--color-premium-border);
		}
	}
}

.downloadButtons > button > span {
	font-size: 3em;
}
</style>
