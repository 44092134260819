import { collection, doc, addDoc, deleteDoc, setDoc, updateDoc } from 'firebase/firestore';
import Firebase from './core';
import { RPC } from './rpc';
import { dialogStore, onlineProject } from '@/main';
import { AccountSettings, Collection, maxAllowedFreeProjects, RPCResponse, type Project } from '@/structures';

enum OperationType {
	Collection = 'collections',
	Comment = 'comments',
	Project = 'projects',
	User = 'users'
}

type CreateData<T> =
	T extends OperationType.Collection	? { title?: string } :
	T extends OperationType.Project		? {} :
	T extends OperationType.User		? {} :
	never;

type UpdateData<T> =
	T extends OperationType.Collection	? Partial<Collection> :
	T extends OperationType.Project		? Partial<Project> :
	T extends OperationType.User		? Partial<AccountSettings> :
	never;

export class Operation {
	public static Type = OperationType;

	public static async create <T extends OperationType> (type: T, data: CreateData<T>): Promise<number | string | undefined> {
		switch (type) {
			case OperationType.Collection:
				if (!Firebase.user.uid.value) return;
				Firebase.loading.value = true;
				const newCollection = new Collection(Firebase.user.uid.value);
				if ('title' in data && data.title) newCollection.title = data.title;
				const { id } = await addDoc(collection(Firebase.database, type).withConverter(Collection), newCollection);
				Firebase.loading.value = false;
				return id;

			case OperationType.Project:
				if (!Firebase.user.authenticated.value) return;
				Firebase.loading.value = true;
				if (
					!Firebase.user.subscribed.value
					&& Firebase.projects.value.size >= maxAllowedFreeProjects
				) { // Shortcut if the user is not subscribed.
					return RPCResponse.SubscriptionRequired().status;
				}
				const response = await RPC(RPC.Endpoint.createProject, {});
				if (response.success && response.message) {
					onlineProject.select(response.message);
				}
				Firebase.loading.value = false;
				return response.status;

			case OperationType.User:
				if (!Firebase.user.uid.value) return;
				await setDoc(doc(Firebase.database, `${type}/${Firebase.user.uid.value}`).withConverter(AccountSettings), new AccountSettings());
				break;
		}
	}

	public static async update <T extends OperationType> (type: T, id: string, data: UpdateData<T>): Promise<void> {
		switch (type) {
			case OperationType.Collection:
				if (!Firebase.user.authenticated.value || !id) return;
				const coll = Firebase.collections.value.get(id);
				if (coll) Object.assign(coll, data);
				for (const [property, value] of Object.entries(data)) {
					Firebase.collectionBatcher.write(id, property, value);
				}
				break;

			case OperationType.Project:
				if (!Firebase.user.authenticated.value || !id) return;
				const project = Firebase.projects.value.get(id);
				if (!project) return;
				Object.assign(project, data);
				for (const [property, value] of Object.entries(data)) {
					if (property === 'css' || property === 'compressedCss') {
						Firebase.projectBatcher.write(id, 'css', project.compressedCss);
						continue;
					}
					Firebase.projectBatcher.write(id, property, value);
				}
				break;

			case OperationType.User:
				if (!Firebase.user.uid.value) return;
				if (Firebase.settings.value && data) {
					Object.assign(Firebase.settings.value, data);
					await updateDoc(
						doc(Firebase.database, `${type}/${Firebase.user.uid.value}`),
						{
							biography: Firebase.settings.value.biography,
							theme: Firebase.settings.value.theme
						}
					);
				}
				break;

			default:
				break;
		}
	}

	public static async delete <T extends Exclude<OperationType, OperationType.Comment>> (type: T, id: string): Promise<boolean>;
	public static async delete <T extends OperationType.Comment> (type: T, id: string, projectId: string): Promise<boolean>;
	public static async delete <T extends OperationType> (type: T, id: string, projectId?: string): Promise<boolean> {
		switch (type) {
			case OperationType.Collection: {
				if (!Firebase.user.authenticated.value || !id) return false;
				Firebase.loading.value = true;
				let confirmation = window.confirm('Are you sure you want to delete this collection?');
				if (!confirmation) return false;
				try {
					await deleteDoc(doc(Firebase.database, `${type}/${id}`));
					Firebase.loading.value = false;
					return true;
				} catch (error) {
					console.warn('Failed to delete collection:', error);
					dialogStore.open(dialogStore.Dialog.Notification, 'Something went wrong.');
					Firebase.loading.value = false;
					return false;
				}
			}

			case OperationType.Comment: {
				if (!Firebase.user.authenticated.value || !id || !projectId) return false;
				Firebase.loading.value = true;
				let confirmation = window.confirm('Are you sure you want to delete this comment?');
				if (!confirmation) return false;
				try {
					await deleteDoc(doc(Firebase.database, `projects/${projectId}/${type}/${id}`));
					Firebase.loading.value = false;
					return true;
				} catch (error) {
					console.warn('Failed to delete comment:', error);
					dialogStore.open(dialogStore.Dialog.Notification, 'Something went wrong.');
					Firebase.loading.value = false;
					return false;
				}
			}

			case OperationType.Project: {
				if (!Firebase.user.authenticated.value || !id) return false;
				Firebase.loading.value = true;
				let confirmation = window.confirm('Are you sure you want to delete this project?');
				if (!confirmation) return false;
				try {
					await deleteDoc(doc(Firebase.database, `${type}/${id}`));
					if (Firebase.projects.value.size <= 0) {
						await this.create(OperationType.Project, {});
					}
				} catch (error) {
					console.warn('Failed to delete project:', error);
					dialogStore.open(dialogStore.Dialog.Notification, 'Something went wrong.');
					return false;
				}
				Firebase.loading.value = false;
				return true;
			}

			default:
				return false;
		}
	}
}
