<script setup lang="ts">
import { onBeforeMount, onMounted, onUnmounted, ref, watch, type WatchStopHandle } from 'vue';
import { flagsStore, userStore } from '@/main';
import Firebase from '@/modules/firebase/core';
import { RPC } from '@/modules/firebase/rpc';
import { wall } from '@/modules/firebase/walls';
import DialogBox from '@/components/DialogBox.vue';
import Icon from '@/components/Icon.vue';
import LandingSplash from '@/components/LandingSplash.vue';
import NavBar from '@/components/NavBar.vue';
import PaginationTrigger from '@/components/PaginationTrigger.vue';
import ProjectCard from '@/components/ProjectCard.vue';

const publicProjects = ref(new Set<string>());
const featuredProjects = ref(['', '', '', '']);
const shelves = ref<Record<string, Array<string>>>({});
const loadingFeatures = ref(false);
const loadingShelves = ref(new Set<string>());
const generator = Firebase.getMostRecentPublicProjectsPaginated(10);
const shelfToAdd = ref('');
const showAddShelfDialog = ref(false);
const showSkeletons = ref(true);
const moreProjectsCanBeLoaded = ref(true);
const mobileArrangement = ref(false);
const search = ref('');
const mobileSearchInput = ref<HTMLInputElement | null>(null);
const showSearchInput = ref(false);
const paginationTrigger = ref(false);
const unwatchers = new Array<WatchStopHandle>();
let mobileQuery: MediaQueryList;

function showMobileSearch (): void {
	showSearchInput.value = mobileArrangement.value;
	if (showSearchInput.value) {
		window.setTimeout(() => {
			mobileSearchInput.value?.focus({ preventScroll: true });
		}, 200);
	}
}
async function loadFeatures (): Promise<void> {
	if (!flagsStore.features) return;
	if (loadingFeatures.value) return;
	loadingFeatures.value = true;
	featuredProjects.value = await Firebase.getFeaturedProjects(true);
	loadingFeatures.value = false;
}
async function loadMoreProjects (): Promise<void> {
	if (!moreProjectsCanBeLoaded.value) return;
	// Show the skeletons.
	showSkeletons.value = true;
	// Load the projects.
	const projects = (await generator.next()).value;
	// Remove the skeletons.
	showSkeletons.value = false;
	if (projects.length <= 0) {
		moreProjectsCanBeLoaded.value = false;
		return;
	}
	for (const project of projects) {
		publicProjects.value.add(project);
	}
	if (paginationTrigger.value) await loadMoreProjects();
}
function updateMobileArrangement (query: MediaQueryList | MediaQueryListEvent): void {
	mobileArrangement.value = query.matches;
}

async function loadTagShelves (): Promise<void> {
	if (!Firebase.config.value?.shelves.length) {
		shelves.value = {};
		return;
	}
	for (const tag in shelves.value) {
		if (!Firebase.config.value.shelves.includes(tag)) {
			delete shelves.value[tag];
		}
	}
	const operations = new Array<Promise<{ tag: string, shelf: Array<string> }>>();
	for (const tag of Firebase.config.value.shelves) {
		shelves.value[tag] = [];
		operations.push((async () => {
			return { tag, shelf: await Firebase.getPublicProjectsByTag('datePublished', true, tag) };
		})());
	}
	for (const result of await Promise.allSettled(operations)) {
		if (result.status === 'rejected') continue;
		shelves.value[result.value.tag] = result.value.shelf;
	}
}

async function adminAddShelf (): Promise<void> {
	if (!wall.admin()) return;
	if (!shelfToAdd.value) return;
	loadingShelves.value.add(shelfToAdd.value);
	shelves.value[shelfToAdd.value] = [];
	await RPC(RPC.Endpoint.adminAddShelf, { tag: shelfToAdd.value });
	shelves.value[shelfToAdd.value] = await Firebase.getPublicProjectsByTag('datePublished', true, shelfToAdd.value);
	loadingShelves.value.delete(shelfToAdd.value);
	shelfToAdd.value = '';
	showAddShelfDialog.value = false;
}

async function adminRemoveShelf (tag: string): Promise<void> {
	if (!wall.admin()) return;
	if (!window.confirm('Are you sure you want to remove this shelf?')) return;
	loadingShelves.value.add(tag);
	await RPC(RPC.Endpoint.adminRemoveShelf, { tag });
	delete shelves.value[tag];
	loadingShelves.value.delete(tag);
}

watch(() => Firebase.config.value?.shelves, loadTagShelves, { deep: true });

onBeforeMount(async () => {
	await loadMoreProjects();
	void loadFeatures();
	unwatchers.push(
		watch(paginationTrigger, (value) => value && void loadMoreProjects()),
		watch(() => Firebase.config.value?.featureDate, loadFeatures),
		watch(() => flagsStore.features, loadFeatures),
	);
});
onMounted(() => {
	// Arrange the page vertically if the viewport width is too small.
	mobileQuery = window.matchMedia('only screen and (max-width: 50rem)');
	updateMobileArrangement(mobileQuery);
	mobileQuery.addEventListener('change', updateMobileArrangement);
});
onUnmounted(() => {
	for (const unwatch of unwatchers) unwatch?.();
	mobileQuery?.removeEventListener('change', updateMobileArrangement);
});
</script>

<template>
	<NavBar>
		<form
			class="searchBar"
			title="Search"
			@submit.prevent=""
			data-journey-id="BrowseSearch"
		>
			<label for="searchInput">
				<input
					type="search"
					id="searchInput"
					name="searchInput"
					placeholder="Search"
					@focus="mobileArrangement && showMobileSearch()"
					:value="search"
					@input="search = ($event.target as HTMLInputElement).value"
				>
			</label>
			<button type="submit" @click="showMobileSearch()"><Icon>search</Icon></button>
		</form>
		<form
			class="searchBar searchBarFixed"
			:class="{ show: showSearchInput }"
			@submit.prevent=""
			data-journey-id="BrowseSearch"
		>
			<label for="mobileSearchInput">
				<input
					ref="mobileSearchInput"
					type="text"
					id="mobileSearchInput"
					name="mobileSearchInput"
					placeholder="Search"
					:value="search"
					@input="search = ($event.target as HTMLInputElement).value"
				>
			</label>
			<button @click="showSearchInput = false"><Icon>close</Icon></button>
		</form>
	</NavBar>

	<LandingSplash variant="top" />

	<main>
		<section class="publicProjects">
			<template v-if="flagsStore.features">
				<div class="shelf featured">
					<h2>Featured</h2>
					<ul>
						<li v-for="project of featuredProjects">
							<ProjectCard :project :search />
						</li>
					</ul>
				</div>
				<div v-for="shelf, tag in shelves" class="shelf tag" :class="{ loading: !shelf.length || loadingShelves.has(tag) }">
					<div class="titleRow">
						<h2>#{{ tag }}</h2>
						<div v-if="userStore.adminMode" class="adminOptions">
							<button
								class="danger"
								title="Remove shelf"
								@click="adminRemoveShelf(tag)"
							>
								<span v-if="loadingShelves.has(tag)" class="spinner"></span>
								<Icon v-else>delete</Icon>
							</button>
						</div>
					</div>
					<ul>
						<li v-for="project of shelf">
							<ProjectCard :project :search />
						</li>
					</ul>
				</div>
			</template>
			<div v-if="userStore.adminMode" class="adminAddShelf">
				<button
					class="primary danger"
					@click="showAddShelfDialog = true"
				>
					<Icon>add</Icon><span>Add shelf</span>
				</button>
			</div>
			<div class="shelf">
				<h2>All</h2>
				<ul>
					<li v-for="project of publicProjects">
						<ProjectCard :project :search />
					</li>
					<template v-if="showSkeletons">
						<li v-for="index of 10">
							<ProjectCard project="" />
						</li>
					</template>
				</ul>
			</div>
		</section>

		<PaginationTrigger v-model="paginationTrigger" />
	</main>

	<DialogBox
		v-if="wall.admin()"
		title="Add a Shelf"
		v-model="showAddShelfDialog"
	>
		<form class="adminAddShelfDialog" @submit.prevent="adminAddShelf()">
			<label for="shelfToAdd">Shelf Tag
				<input
					type="text"
					id="shelfToAdd"
					name="shelfToAdd"
					v-model="shelfToAdd"
				>
			</label>
			<button type="submit" :disabled="loadingShelves.size > 0">
				<span v-if="loadingShelves.size" class="spinner"></span>
				<template v-else>Add</template>
			</button>
		</form>
	</DialogBox>
</template>

<style scoped>
section.publicProjects {
	min-height: 100vh;
	padding-top: 1rem;
}

section.publicProjects > .adminAddShelf {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 0;
	margin: 0.75rem 0 2rem;
	border-top: 1px solid var(--color-danger);
}

section.publicProjects > .shelf {
	width: 100%;
	min-height: 19rem;
	margin-bottom: 1rem;
	padding: 1.5ch 0 0 0;
	background-color: var(--color-border-lighter);
	border-radius: var(--border-radius);
}
section.publicProjects > .shelf.featured {
	color: black;
	background-color: var(--color-primary-contrast);
	background: linear-gradient(130deg, var(--color-primary-contrast), #aeb3f9);
	background: linear-gradient(130deg, var(--color-primary-light), var(--color-primary-light));
}
section.publicProjects > .shelf.loading::after {
	content: '';
	position: absolute;
	inset: 0;
	background-color: #888;
	background: linear-gradient(90deg, #888 60%, #bbb 95%, #888);
	background-attachment: fixed;
	background-position: 0 0;
	background-repeat: repeat;
	background-size: 200vmax 200vmax;
	border-radius: inherit;
	animation: loadingPulse 2.5s linear infinite;
}

section.publicProjects > .shelf > .titleRow {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: 1rem;
}
section.publicProjects > .shelf h2 {
	margin: 0;
	margin-left: 1rem;
	font-size: large;
}
section.publicProjects > .shelf > ul {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	gap: 0.5rem 1.5rem;
	width: 100%;
	margin: 0 0 2rem;
	padding: 0 1rem 1rem;
	list-style: none;
}
section.publicProjects > .shelf:is(.featured, .tag) > ul {
	flex-wrap: nowrap;
	margin: 0;
	overflow-y: hidden;
	overflow-x: scroll;
	mask-image: linear-gradient(90deg, transparent, black 1rem, black calc(100% - 1rem), transparent);
	scrollbar-color: var(--color-border) transparent;
	scrollbar-gutter: stable;
}
section.publicProjects > .shelf > ul > li {
	margin: 0;
	padding: 0;
}
section.publicProjects > .shelf > ul > li:empty {
	display: none;
}

form.searchBar {
	z-index: 2;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 1ch;
}
form.searchBarFixed {
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	width: 100vw;
	height: 2.5rem;
	background-color: var(--color-background);
}
form.searchBar button {
	z-index: 2;
	height: 100%;
	padding-block: 0;
}
form.searchBar label {
	z-index: 1;
	width: 24ch;
	height: 100%;
	border-radius: var(--border-radius);
	overflow: hidden;
}
form.searchBar input {
	width: 100%;
	height: 100%;
	padding: 0.7ch 1ch;
	color: var(--color-text);
	background-color: var(--color-border-light);
	border: 1px solid transparent;
	border-radius: var(--border-radius);
}
form.searchBar input:focus-visible {
	border: 1px solid var(--color-border);
}
form.searchBar input::-webkit-search-cancel-button {
	filter: grayscale(1) brightness(0);
}
html.dark form.searchBar input::-webkit-search-cancel-button {
	filter: grayscale(1) brightness(10);
}

form.adminAddShelfDialog {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 1.5rem;
}
form.adminAddShelfDialog label {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 0.5ch;
	width: 100%;
}

@media screen and (max-width: 90rem) {
	section.publicProjects > .shelf:not(.featured, .tag) > ul {
		justify-content: center;
	}
}
@media screen and (max-width: 50rem) {
	section.publicProjects {
		padding: 0;
	}
	section.publicProjects > .shelf {
		border-radius: 0;
	}
	form.searchBar:not(.searchBarFixed) label {
		width: 0;
		opacity: 0;
	}
	form.searchBarFixed.show {
		display: flex;
	}
}
</style>
