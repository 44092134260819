<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';

const props = defineProps({
	modelValue: { type: Number, required: true, default: 1 },
	default: { type: Number, required: false, default: 1 },
	min: { type: Number, required: false, default: 0 },
	max: { type: Number, required: false, default: 2 },
	horizontal: { type: Boolean, required: false, default: false },
	vertical: { type: Boolean, required: false, default: false },
	panels: { type: Number, required: false, default: 2 }
});

const emit = defineEmits(['update:modelValue', 'resizing']);
const resizing = ref(false);
const resizer = ref<HTMLDivElement | null>(null);
const parentElement = ref<HTMLElement | null>(null);

function startResize (): void { resizing.value = true; }
function endResize (): void { resizing.value = false; }
function resize (position: number): void {
	if (!resizing.value || !parentElement.value) return;
	let ratio = props.modelValue;
	const parentBox = parentElement.value.getBoundingClientRect();
	if (props.horizontal) {
		ratio = (position - parentBox.top) * props.panels / parentBox.height;
	} else {
		ratio = (position - parentBox.left) * props.panels / parentBox.width;
	}
	emit('update:modelValue', ratio < props.min ? props.min :
							  ratio > props.max ? props.max :
							  ratio);
}
function resetResize (): void {
	emit('update:modelValue', props.default);
	endResize();
	emit('resizing', false);
}
function mouseMove (event: MouseEvent): void {
	if (resizing.value) event.preventDefault();
	resize(props.horizontal ? event.clientY : event.clientX);
}
function touchMove (event: TouchEvent): void {
	if (resizing.value) event.preventDefault();
	resize(props.horizontal ? event.touches[0].clientY : event.touches[0].clientX);
}
function keyboardResize (event: KeyboardEvent): void {
	if (!resizer.value || !parentElement.value) return;
	const parentBox = parentElement.value.getBoundingClientRect();
	const position = resizer.value.getBoundingClientRect();
	let ratio = props.modelValue;
	switch (event.key) {
		case 'ArrowUp':
			if (!props.horizontal) return;
			ratio = (position.y - parentBox.top) * props.panels / parentBox.height - 0.1;
			break;
		case 'ArrowDown':
			if (!props.horizontal) return;
			ratio = (position.y - parentBox.top) * props.panels / parentBox.height + 0.1;
			break;
		case 'ArrowLeft':
			if (props.horizontal) return;
			ratio = (position.x - parentBox.left) * props.panels / parentBox.width - 0.1;
			break;
		case 'ArrowRight':
			if (props.horizontal) return;
			ratio = (position.x - parentBox.left) * props.panels / parentBox.width + 0.1;
			break;
		default:
			return;
	}
	emit('update:modelValue', ratio < props.min ? props.min :
							  ratio > props.max ? props.max :
							  ratio);
}

watch(resizing, (value) => emit('resizing', value));
onMounted(() => {
	if (!resizer.value) return;
	parentElement.value = resizer.value.parentElement;
	parentElement.value?.addEventListener('mousemove', mouseMove);
	parentElement.value?.addEventListener('touchmove', touchMove);
	parentElement.value?.addEventListener('mouseup', endResize);
	parentElement.value?.addEventListener('mouseleave', endResize);
	parentElement.value?.addEventListener('touchend', endResize);
	parentElement.value?.addEventListener('touchcancel', endResize);
});
onUnmounted(() => {
	parentElement.value?.removeEventListener('mousemove', mouseMove);
	parentElement.value?.removeEventListener('touchmove', touchMove);
	parentElement.value?.removeEventListener('mouseup', endResize);
	parentElement.value?.removeEventListener('mouseleave', endResize);
	parentElement.value?.removeEventListener('touchend', endResize);
	parentElement.value?.removeEventListener('touchcancel', endResize);
});
</script>

<template>
	<div
		ref="resizer"
		class="resizer"
		:class="{ horizontal, resizing }"
		tabindex="0"
		@mousedown="startResize()"
		@touchstart="startResize()"
		@dblclick="resetResize()"
		@keydown="keyboardResize($event)"
		data-journey-id="Resizer"
	>
		<span class="hitbox"></span>
	</div>
</template>

<style>
.resizer {
	z-index: 5;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0px;
	min-width: 0px;
	height: 100%;
	transition: 0.1s ease background-color;
	cursor: ew-resize;
}
.resizer.horizontal {
	width: 100%;
	min-width: unset;
	height: 0px;
	min-height: 0px;
	cursor: ns-resize;
}
.resizer > .hitbox {
	position: absolute;
	display: block;
	width: calc(100% + 16px);
	height: 100%;
}
.resizer.horizontal > .hitbox {
	width: 100%;
	height: calc(100% + 16px);
}
.resizer::before {
	content: '';
	position: absolute;
	display: block;
	width: calc(100% + 3px);
	height: 3rem;
	background-color: var(--color-text);
	border-radius: var(--border-radius);
	opacity: 0.2;
	transition:
		0.1s ease width,
		0.1s ease height,
		0.1s ease background-color,
		0.1s ease opacity;
}
.resizer.horizontal::before {
	width: 3rem;
	height: calc(100% + 3px);
}
.resizer:hover::before,
.resizer:focus::before,
.resizer.resizing::before {
	width: calc(100% + 3px);
	height: calc(100% - 1rem);
	background-color: var(--color-primary);
	opacity: 0.8;
	box-shadow: 0 0 8px -2px #0008;
}
html.dark .resizer:hover::before,
html.dark .resizer:focus::before,
html.dark .resizer.resizing::before {
	background-color: var(--color-text);
}
.resizer.horizontal:hover::before,
.resizer.horizontal:focus::before,
.resizer.horizontal.resizing::before {
	width: calc(100% - 1rem);
	height: calc(100% + 3px);
}

@media not all and (hover: none) {
	.resizer:hover::before {
		width: calc(100% + 3px);
		height: calc(100% - 1rem);
		background-color: var(--color-primary);
		opacity: 0.8;
		box-shadow: 0 0 8px -2px #0008;
	}
	html.dark .resizer:hover::before {
		background-color: var(--color-text);
	}
	.resizer.horizontal:hover::before {
		width: calc(100% - 1rem);
		height: calc(100% + 3px);
	}
}
</style>
