<script setup lang="ts">
import { nextTick, ref, watch } from 'vue';
import Icon from '@/components/Icon.vue';

const props = defineProps({
	message: { type: String, required: true },
	previous: { type: String, required: true },
	next: { type: String, required: true },
	secondary: { type: String, required: false },
	element: { type: String, required: false },
	icon: { type: String, required: false },
	index: { type: Number, required: false }
});

const emit = defineEmits(['previous', 'next']);

const wizardTooltip = ref<HTMLDivElement | null>(null);
const position = ref({ top: '50%', left: '50%' });
const translateX = ref('-50%');
const translateY = ref('-50%');
const offset = 10;

function resetPosition () {
	position.value.top = '50%';
	position.value.left = '50%';
	translateX.value = '-50%';
	translateY.value = '-50%';
}
function refreshPosition () {
	void nextTick(() => {
		if (!props.element || !wizardTooltip.value) {
			resetPosition();
			return;
		}
		const target = document.querySelector(props.element)?.getBoundingClientRect();
		if (!target) {
			resetPosition();
			return;
		}
		const dialog = wizardTooltip.value.getBoundingClientRect();
		let top = target.y + (target.height / 2) - (dialog.height / 2);
		let left = target.x + target.width + offset;
		if (left + dialog.width > window.innerWidth) {
			left = target.x - dialog.width - offset;
		}
		if (Math.max(target.top, top) < Math.min(target.top + target.height, top + dialog.height)) {
			top = target.y + target.height + offset;
		}
		if (top + dialog.height > window.innerHeight) {
			top = target.y - dialog.height - offset;
		}
		if (top < 0) {
			position.value.top = '50%';
			translateY.value = '-50%';
		} else {
			position.value.top = `${top}px`;
			translateY.value = '0%';
		}
		if (left < 0) {
			position.value.left = '50%';
			translateX.value = '-50%';
		} else {
			position.value.left = `${left}px`;
			translateX.value = '0%';
		}
	});
}

watch(props, refreshPosition, { deep: true });
</script>

<template>
	<div ref="wizardTooltip" class="wizardTooltip" :style="position" data-journey-id="WizardTooltip">
		<div class="index">
			<Icon v-if="props.icon" style="font-size: x-large;">{{ props.icon }}</Icon>
			<span v-else>{{ props.index }}.</span>
		</div>
		<div class="message">
			<p>{{ props.message }}</p>
			<p v-if="props.secondary">{{ props.secondary }}</p>
		</div>
		<div class="options">
			<button @click="emit('previous');refreshPosition()">{{ previous }}</button>
			<button @click="emit('next');refreshPosition()">{{ next }}</button>
		</div>
	</div>
</template>

<style scoped>
.wizardTooltip {
	position: absolute;
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto auto;
	gap: 1rem;
	min-width: 20ch;
	max-width: 38ch;
	padding: 0.7rem;
	background-color: var(--color-background);
	border: 1px solid var(--color-border);
	border-radius: var(--border-radius);
	transform: translate(v-bind(translateX), v-bind(translateY));
	opacity: 0;
	animation: fadeIn 0.2s 0.1s ease forwards;
	transition: 0.2s ease top, 0.2s ease left, 0.2s ease transform;
}

.wizardTooltip > .index {
	font-size: larger;
}

.wizardTooltip > .message p {
	margin: 0;
}
.wizardTooltip > .message p:nth-of-type(2) {
	margin-block-start: 1.2ch;
}

.wizardTooltip > .options {
	grid-area: 2 / 1 / 3 / 4;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	align-items: center;
	gap: 0.5rem;
	width: 100%;
}

button {
	min-width: 6ch;
	border-color: transparent;
}
button:nth-of-type(2) {
	background-color: var(--color-primary-harmony);
}
</style>
