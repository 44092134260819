<script setup lang="ts" generic="T extends boolean | string">
import { computed } from 'vue';
import Icon from '@/components/Icon.vue';

interface Props {
	modelValue: T;
	name: string;
	label?: string;
	options?: Array<string>;
	checkedIcon?: string;
	uncheckedIcon?: string;
	checkedText?: string;
	uncheckedText?: string;
	danger?: boolean;
	loading?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits(['update:modelValue']);

const checked = computed(() => props.modelValue === props.options?.[1] || props.modelValue === true);
const color = computed(() => props.danger ? 'var(--color-danger)' : 'var(--color-primary)');

function updateModel (state: boolean): void {
	emit('update:modelValue', props.options?.[Number(state)] ?? state);
}
</script>

<template>
	<label :for="name" class="toggleInput">
		<span v-if="label" class="label">{{ label }}</span>
		<div class="toggle">
			<input
				type="checkbox"
				:id="name"
				:name
				:checked
				@input="updateModel(($event.target as HTMLInputElement).checked)"
			>
			<Icon
				class="slide"
				:data-checked="checkedIcon"
				:data-unchecked="uncheckedIcon"
			/>
		</div>
		<span v-if="checkedText || uncheckedText" class="state">
			<span v-if="loading" class="spinner"></span>
			<template v-else>{{ checked ? checkedText : uncheckedText }}</template>
		</span>
	</label>
</template>

<style scoped>
.toggleInput {
	display: grid;
	grid-template-columns: 2.75em auto;
	grid-template-rows: auto 1.5em;
	grid-template-areas:
		'label  label'
		'toggle state';
	align-items: center;
}

.label {
	grid-area: label;
}

.toggle {
	grid-area: toggle;
	width: 100%;
	height: 100%;
	border: 1px solid var(--color-border-light);
	border-radius: 1em;
	overflow: hidden;
	cursor: pointer;
	user-select: none;
}

.toggle > input {
	position: absolute;
	opacity: 0;
}
.toggle > .slide {
	display: block;
	height: 100%;
	aspect-ratio: 1;
	margin-left: 0;
	transform: translateX(0%);
	transition: 0.1s ease margin-left, 0.1s ease transform;
}
.toggle > input:checked + .slide {
	margin-left: 100%;
	transform: translateX(-100%);
}
.toggle > .slide::before {
	content: '';
	position: absolute;
	right: 50%;
	display: block;
	width: 200%;
	height: 100%;
	background-color: v-bind(color);
	opacity: 0;
	transition: 0s linear opacity;
	transition-delay: 0.1s;
}
.toggle > input:checked + .slide::before {
	opacity: 1;
	transition-delay: 0s;
}
.toggle > .slide::after {
	content: attr(data-unchecked);
	position: absolute;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	font-family: inherit;
	line-height: 1;
	text-align: center;
	background-color: white;
	border: 1px solid #eee;
	border-radius: 50%;
}
.toggle > input:checked + .slide::after {
	content: attr(data-checked);
}

.state {
	grid-area: state;
	margin-inline-start: 2ch;
	font-size: small;
	opacity: 0.6;
	transition: 0.2s ease opacity;

	&:has(.spinner) {
		opacity: 1;
	}
}
</style>
