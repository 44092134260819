export { Cache } from './cache';

import { collection, documentId, getDocs, where, query, orderBy } from 'firebase/firestore';
import Firebase from '@/modules/firebase/core';
import { Cache } from '@/modules/caches/cache';
import { RPC } from '@/modules/firebase/rpc';
import { Collection, Comment, Project } from '@/structures';
import type { ProjectLink } from '@/structures';

export const publicUsers = new Cache(async (uids) => {
	const response = await RPC(RPC.Endpoint.getUserInfo, { uids });
	return response.data;
});

export const projectLinks = new Cache(async (shortProjectIds) => {
	const output: { [id: string]: string } = {};
	const result = await getDocs(query(collection(Firebase.database, 'projectLinks'), where('link', 'in', shortProjectIds)));
	for (const document of result.docs) {
		const data = document.data() as ProjectLink | undefined;
		if (data) output[data.link] = data.projectId;
	}
	return output;
});

export const publicProjects = new Cache(async (projectIds) => {
	const output: { [id: string]: Project } = {};
	const fullIds = await projectLinks.getMultiple(projectIds.filter((id) => id.length <= 8));
	for (const id of projectIds) {
		if (id.length > 8) fullIds.set(id, id);
	}
	const result = await getDocs(query(
		collection(Firebase.database, 'projects').withConverter(Project),
		where('public', '==', true),
		where(documentId(), 'in', [ ...fullIds.values() ])
	));
	for (const document of result.docs) {
		const data = document.data();
		if (data) output[document.id] = data;
	}
	return output;
});

export const publicComments = new Cache(async (projectIds) => {
	const output: { [id: string]: Array<{ id: string, comment: Comment }> } = {};
	const projects = await publicProjects.getMultiple(projectIds);
	for (const projectId of projects.keys()) {
		const commentDocs = await getDocs(query(
			collection(Firebase.database, 'projects', projectId, 'comments').withConverter(Comment),
			orderBy('pinned', 'desc'),
			orderBy('date', 'desc')
		));
		for (const document of commentDocs.docs) {
			output[projectId] ||= new Array();
			const comment = document.data();
			if (comment) output[projectId].push({ id: document.id, comment });
		}
	}
	return output;
});

export const publicCollections = new Cache(async (collectionIds) => {
	const output: { [id: string]: Collection } = {};
	const result = await getDocs(query(
		collection(Firebase.database, 'collections').withConverter(Collection),
		where(documentId(), 'in', collectionIds)
	));
	for (const document of result.docs) {
		const data = document.data();
		if (data) output[document.id] = data;
	}
	return output;
});
