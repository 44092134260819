import { httpsCallable } from 'firebase/functions';
import Firebase from '@/modules/firebase/core';
import { type DefaultRPCResponse, type PublicUserInfo, RPCResponse as RPCResponseLiteral, ImageFormat } from '@/structures';
import { flagsStore } from '@/main';

enum RPCEndpoint {
	adminAddShelf = 'adminAddShelf',
	adminDeleteComment = 'adminDeleteComment',
	adminDeleteProject = 'adminDeleteProject',
	adminRemoveShelf = 'adminRemoveShelf',
	adminShadowComment = 'adminShadowComment',
	adminShadowProject = 'adminShadowProject',
	addImageTask = 'addImageTask',
	commentOnProject = 'commentOnProject',
	createCheckoutSession = 'createCheckoutSession',
	createPortalCheckoutSession = 'createPortalCheckoutSession',
	createProject = 'createProject',
	deleteAccount = 'deleteAccount',
	getRelatedCollections = 'getRelatedCollections',
	getRelatedProjects = 'getRelatedProjects',
	getUserInfo = 'getUserInfo',
	likeProject = 'likeProject',
	liveSession = 'liveSession',
	pinComment = 'pinComment',
	pinProjectToProfile = 'pinProjectToProfile',
	publishProject = 'publishProject',
	reportComment = 'reportComment',
	reportProject = 'reportProject',
	suggestFeature = 'suggestFeature',
	unpublishProject = 'unpublishProject',
	updateProfileLink = 'updateProfileLink'
}

type RPCData<T> =
	T extends RPCEndpoint.adminAddShelf					? { tag: string; } :
	T extends RPCEndpoint.adminDeleteComment			? { commentId: string; projectId: string; } :
	T extends RPCEndpoint.adminDeleteProject			? { projectId: string; } :
	T extends RPCEndpoint.adminRemoveShelf				? { tag: string; } :
	T extends RPCEndpoint.adminShadowComment			? { commentId: string; projectId: string; } :
	T extends RPCEndpoint.adminShadowProject			? { projectId: string; } :
	T extends RPCEndpoint.addImageTask					? { projectId: string; format: ImageFormat; width: number; height: number; } :
	T extends RPCEndpoint.commentOnProject				? { projectId: string; message: string; } :
	T extends RPCEndpoint.createCheckoutSession			? { lookupKey: string; } :
	T extends RPCEndpoint.createPortalCheckoutSession	? {} :
	T extends RPCEndpoint.createProject					? {} :
	T extends RPCEndpoint.deleteAccount					? {} :
	T extends RPCEndpoint.getRelatedCollections			? { uid: string; } :
	T extends RPCEndpoint.getRelatedProjects			? { uid: string; } :
	T extends RPCEndpoint.getUserInfo					? { uids: Array<string>; } :
	T extends RPCEndpoint.likeProject					? { projectId: string; } :
	T extends RPCEndpoint.liveSession					? { projectId: string; operation: 'start' | 'stop'; } :
	T extends RPCEndpoint.pinComment					? { commentId: string; projectId: string; } :
	T extends RPCEndpoint.pinProjectToProfile			? { projectId: string; } :
	T extends RPCEndpoint.publishProject				? { projectId: string; } :
	T extends RPCEndpoint.reportComment					? { commentId: string; projectId: string; message?: string; } :
	T extends RPCEndpoint.reportProject					? { projectId: string; message?: string; } :
	T extends RPCEndpoint.suggestFeature				? { message: string; } :
	T extends RPCEndpoint.unpublishProject				? { projectId: string; } :
	T extends RPCEndpoint.updateProfileLink				? { link: string; } :
	never;

type RPCResponse<T> =
	T extends RPCEndpoint.adminAddShelf					? DefaultRPCResponse :
	T extends RPCEndpoint.adminDeleteComment			? DefaultRPCResponse :
	T extends RPCEndpoint.adminDeleteProject			? DefaultRPCResponse :
	T extends RPCEndpoint.adminRemoveShelf				? DefaultRPCResponse :
	T extends RPCEndpoint.adminShadowComment			? RPCResponseMessage<'cleared' | 'shadowed'> :
	T extends RPCEndpoint.adminShadowProject			? RPCResponseMessage<'cleared' | 'shadowed'> :
	T extends RPCEndpoint.addImageTask					? DefaultRPCResponse :
	T extends RPCEndpoint.commentOnProject				? DefaultRPCResponse :
	T extends RPCEndpoint.createCheckoutSession			? DefaultRPCResponse :
	T extends RPCEndpoint.createPortalCheckoutSession	? DefaultRPCResponse :
	T extends RPCEndpoint.createProject					? DefaultRPCResponse :
	T extends RPCEndpoint.deleteAccount					? DefaultRPCResponse :
	T extends RPCEndpoint.getRelatedCollections			? RPCResponseData<Array<string>> :
	T extends RPCEndpoint.getRelatedProjects			? RPCResponseData<Array<string>> :
	T extends RPCEndpoint.getUserInfo					? RPCResponseData<Record<string, PublicUserInfo>> :
	T extends RPCEndpoint.likeProject					? RPCResponseMessage<'added' | 'error' | 'removed'> :
	T extends RPCEndpoint.liveSession					? DefaultRPCResponse :
	T extends RPCEndpoint.pinComment					? RPCResponseMessage<'error' | 'pinned' | 'unpinned'> :
	T extends RPCEndpoint.pinProjectToProfile			? DefaultRPCResponse :
	T extends RPCEndpoint.publishProject				? DefaultRPCResponse :
	T extends RPCEndpoint.reportComment					? DefaultRPCResponse :
	T extends RPCEndpoint.reportProject					? DefaultRPCResponse :
	T extends RPCEndpoint.suggestFeature				? DefaultRPCResponse :
	T extends RPCEndpoint.unpublishProject				? DefaultRPCResponse :
	T extends RPCEndpoint.updateProfileLink				? DefaultRPCResponse :
	never;

type RPCResponseData<T> = Omit<DefaultRPCResponse, 'data'> & { data: T };
type RPCResponseMessage<T extends string> = Omit<DefaultRPCResponse, 'message'> & { message?: T | (string & {}) };

type RPCCallback<T> = (response: RPCResponse<T>) => void | Promise<void>;

export interface RPCOptions<T> {
	/** *(Optional)* A function to call when the RPC is complete. */
	callback?: RPCCallback<T>;
	/** *(Optional)* A function to call when the emulated RPC is complete. */
	emulatedCallback?: RPCCallback<T>;
}

/**
 * Make a remote procedure call.
 * @param endpoint The endpoint to call.
 * @param data The data to send to the endpoint.
 * @param options *(Optional)* Options to configure the call with.
 * @returns The result of the RPC.
 */
export async function RPC <T extends RPCEndpoint> (endpoint: T, data: RPCData<T>, options?: RPCOptions<T>): Promise<RPCResponse<T>> {
	try {
		if (endpoint in Emulator) {
			const emulatedResponse = await Emulator[endpoint](data) as RPCResponse<T>;
			if (!emulatedResponse.success) throw new Error();
			void options?.emulatedCallback?.(emulatedResponse);
		}
		const response = (await httpsCallable(Firebase.functions, endpoint)(data)).data as RPCResponse<T>;
		if (!response) throw new Error();
		void options?.callback?.(response);
		return response;
	} catch (error) {
		switch (endpoint) {
			case RPCEndpoint.getUserInfo:
				return {} as RPCResponse<T>;
			default:
				return RPCResponseLiteral.ServerError() as RPCResponse<T>;
		}
	}
}

RPC.Endpoint = RPCEndpoint;

const Emulator = {
	[RPCEndpoint.likeProject]: async (data: RPCData<RPCEndpoint.likeProject>): Promise<RPCResponse<RPCEndpoint.likeProject>> => {
		if (!flagsStore.likes) return RPCResponseLiteral.Unavailable('error');
		if (!data.projectId) return RPCResponseLiteral.BadRequest('error');
		const project = await Firebase.publicProjects.get(data.projectId);
		if (!project) return RPCResponseLiteral.NotFound('error');
		if (!project.public) return RPCResponseLiteral.NotFound('error');
		const user = await Firebase.publicUsers.get(project.owner);
		if (!user) return RPCResponseLiteral.ServerError('error');
		const remove = Firebase.settings.value?.liked.includes(data.projectId);
		return RPCResponseLiteral.Success(remove ? 'removed' : 'added');
	},
	[RPCEndpoint.pinProjectToProfile]: async (data: RPCData<RPCEndpoint.pinProjectToProfile>): Promise<RPCResponse<RPCEndpoint.pinProjectToProfile>> => {
		if (!Firebase.user.raw.value?.emailVerified) return RPCResponseLiteral.AccountVerificationRequired();
		if (!data.projectId) return RPCResponseLiteral.BadRequest();
		const project = await Firebase.publicProjects.get(data.projectId);
		if (!project) return RPCResponseLiteral.NotFound();
		if (project.owner !== Firebase.user.uid.value) return RPCResponseLiteral.NotFound();
		if (!project.public) return RPCResponseLiteral.NotFound();
		const user = await Firebase.publicUsers.get(Firebase.user.uid.value);
		if (!user) return RPCResponseLiteral.ServerError();
		user.pinnedProject = data.projectId;
		return RPCResponseLiteral.Success();
	}
} as Record<RPCEndpoint, (data: RPCData<RPCEndpoint>) => Promise<RPCResponse<RPCEndpoint>>>;
