<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, onUnmounted, ref, watch, type WatchStopHandle  } from 'vue';
import { offlineProject, onlineProject, theme } from '@/main';
import { type Editor, getConfiguredEditor } from '@/modules/editor/config';
import HTMLGenerator from '@/modules/editor/generator';
import Firebase from '@/modules/firebase/core';
import { Flavour } from '@/structures';
import FocusBridge from '@/components/FocusBridge.vue';
import Icon from '@/components/Icon.vue';
import Resizer from '@/components/Resizer.vue';
import ToggleInput from '@/components/ToggleInput.vue';

let mainEditor: Editor | undefined = undefined;
let compilationPreviewEditor: Editor | undefined = undefined;
const compilationPreview = ref<HTMLDivElement | null>(null);
const cssEditor = ref<HTMLDivElement | null>(null);
const generator = new HTMLGenerator();
const panelRatio = {
	default: 1,
	min: 0.2,
	max: 1.8,
	value: ref(1),
};
const projectReference = computed(() => Firebase.user.authenticated.value ? onlineProject : offlineProject);
const resizing = ref(false);
const showCompilationPreviewInput = ref(false);
const showCompilationPreview = computed(() => showCompilationPreviewInput.value && projectReference.value.flavour !== Flavour.CSS);
const unwatchers = new Array<WatchStopHandle>();

function updateResizing (value: boolean): void {
	resizing.value = value;
}

onMounted(async () => {
	// Compilation preview.
	if (!compilationPreview.value) return;
	compilationPreviewEditor = await getConfiguredEditor(
		compilationPreview.value,
		'',
		'css',
		theme.monaco,
		{ readOnly: true }
	);
	async function updateCompilationPreviewModel (css: string): Promise<void> {
		if (!compilationPreviewEditor) return;
		const parsedCss = await generator.parse(css);
		compilationPreviewEditor.setContent(parsedCss);
	}

	// Main editor.
	if (!cssEditor.value) return;
	mainEditor = await getConfiguredEditor(
		cssEditor.value,
		projectReference.value.css,
		projectReference.value.flavour,
		theme.monaco
	);
	mainEditor.editor.onDidChangeCursorPosition(event => { projectReference.value.cursorPosition = event.position; });
	mainEditor.model.onDidChangeContent(() => {
		if (!mainEditor) return;
		const snapshot = mainEditor.model.createSnapshot().read() || '';
		if (projectReference.value.css !== snapshot) {
			projectReference.value.css = snapshot;
			void updateCompilationPreviewModel(projectReference.value.css);
		}
	});
	void updateCompilationPreviewModel(projectReference.value.css);

	// Generator.
	generator.flavour.value = projectReference.value.flavour as Flavour;

	// Reactive properties.
	unwatchers.push(
		// Code content.
		watch(() => projectReference.value.css, css => {
			if (!mainEditor) return;
			if ((mainEditor.model.createSnapshot().read() || '') !== css) {
				mainEditor.setContent(css);
				void updateCompilationPreviewModel(css);
			}
		}),
		// Theme.
		watch(() => theme.current, () => mainEditor?.setTheme(theme.monaco)),
		// Flavour.
		watch(() => projectReference.value.flavour, flavour => {
			mainEditor?.setLanguage(flavour);
			generator.flavour.value = flavour as Flavour;
			void updateCompilationPreviewModel(projectReference.value.css);
		})
	);
});

onBeforeUnmount(() => {
	compilationPreviewEditor?.remove();
	mainEditor?.remove();
});

onUnmounted(() => {
	for (const unwatch of unwatchers) unwatch?.();
});
</script>

<template>
	<div class="editorContainer" data-journey-id="EditorPanel">
		<div class="editorSettings" data-journey-id="EditorSettings">
			<select name="cssFlavour" id="cssFlavour" title="CSS flavor" v-model="projectReference.flavour">
				<option :value="Flavour.CSS">CSS</option>
				<option :value="Flavour.LESS">LESS</option>
				<option :value="Flavour.SCSS">SCSS</option>
			</select>
			<label
				v-if="projectReference.flavour !== Flavour.CSS"
				for="showCompilationPreview"
				class="compilationPreviewToggle"
				:title="showCompilationPreview ? 'Hide compiled CSS' : 'Show compiled CSS'"
			>
				<Icon>data_object</Icon>
				<ToggleInput name="showCompilationPreview" v-model="showCompilationPreviewInput" />
			</label>
		</div>
		<div class="editors" :style="{ gap: showCompilationPreview ? 'var(--gap)' : '0' }">
			<FocusBridge label="Press enter to use the CSS editor">
				<div
					ref="cssEditor"
					class="editor"
					:style="{
						height: showCompilationPreview ? `${50 * panelRatio.value.value}%` : '100%',
						transition: resizing ? '0s ease height' : '0.4s ease height'
					}"
				></div>
			</FocusBridge>
			<Resizer
				v-if="showCompilationPreview"
				horizontal
				:min="panelRatio.min"
				:max="panelRatio.max"
				@resizing="updateResizing"
				v-model="panelRatio.value.value"
			/>
			<FocusBridge label="Press enter to use the compilation preview editor">
				<div
					ref="compilationPreview"
					class="editor secondary"
					:style="{
						height: showCompilationPreview ? `${100 - 50 * panelRatio.value.value}%` : '0%',
						opacity: showCompilationPreview ? '1' : '0',
						transition: resizing ? '0s ease height, 0s ease opacity' : '0.4s ease height, 0.4s ease opacity'
					}"
				></div>
			</FocusBridge>
		</div>
	</div>
</template>

<style scoped>
.editorContainer {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	height: 100%;
	max-height: 100%;
	padding: 0.25rem 0.5rem 0.5rem 0.25rem;
	overflow: hidden;
}

.editorSettings {
	z-index: 2;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5ch;
	width: 100%;
	padding: 0.5ch;
	background-color: var(--color-background);
	border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
	overflow: hidden;
}

select#cssFlavour {
	padding: 0.5em 0.5em;
	background-color: var(--color-border-light);
	border: unset;
	border-radius: var(--border-radius);
	cursor: pointer;
}
html.dark select#cssFlavour {
	color: white;
}
html.dark select#cssFlavour option {
	color: white;
	background-color: var(--color-background);
}

.compilationPreviewToggle {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.5ch;
	height: 100%;
	padding: 0.25em;
	font-size: 0.8rem;
	background-color: var(--color-border-light);
	border-radius: var(--border-radius);
	cursor: pointer;
	
	& > span {
		font-size: 1.25rem;
	}
}

.editors {
	--gap: 0.5rem;
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	gap: var(--gap);
	width: 100%;
	height: 100%;
	max-height: 100%;
	transition: 0.4s ease gap;
	overflow: hidden;
}

.editor {
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
	overflow: hidden;
}
.editor.secondary {
	border-radius: var(--border-radius-large);
}

@media only screen and (max-width: 50rem) {
	.editors {
		--gap: 0.25rem;
	}
}
</style>
