<script lang="ts" setup>
import { dialogStore, theme } from '@/main';
import DialogBox from '@/components/DialogBox.vue';
import Icon from '@/components/Icon.vue';

defineProps({ modelValue: { type: Boolean, required: true } });
const emit = defineEmits(['update:modelValue']);

function showWizard (): void {
	window.localStorage.setItem('editorWizardComplete', '');
	dialogStore.open(dialogStore.Dialog.Wizard);
}
</script>

<template>
	<DialogBox
		title="Welcome to Cascades"
		:modelValue
		@update:modelValue="emit('update:modelValue', $event)"
		data-journey-id="HelpMenu"
	>
		<p>⬅️ The window on the left is your input editor. Fill this area with CSS to your heart's content.</p>
		<p>➡️ The window on the right is the canvas. This is where your creations come to life!</p>
		<p>Any CSS that you write in the editor will automatically be displayed on the canvas; no need to refresh.</p>
		<p>All CSS features that your browser supports are available to you.</p>
		<p>Have fun! ❤️</p>
		<br>
		<button
			style="width: 100%;width: -webkit-fill-available;"
			@click="showWizard()"
		>
			<Icon>rocket_launch</Icon>
			Show tutorial
		</button>
		<br>
		<a
			class="button"
			style="gap: 1.5ch;font-size: 1rem;"
			href="https://github.com/Cascades-CSS/"
			target="_blank"
			rel="noreferrer"
			data-journey-id="HelpMenuOrgLink"
		>
			<img :src="`https://cdn.simpleicons.org/github/${theme.isDark ? 'fff' : '000'}`" alt="GitHub logo">
			Cascades on GitHub
		</a>
	</DialogBox>
</template>

<style scoped>
span.code {
	padding: 0.2em 1ch;
	font-family: monospace;
	border: 1px solid #888;
	border-radius: var(--border-radius);
}
span.code.selector {
	color: #cc99cd;
}
span.code.property {
	color: #f8c555;
}
</style>
